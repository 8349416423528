import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsAnimated } from '../../../redux/cache-slice';
import { fetchCarDetails } from '../../../redux/leaseApp/lease-app-slice';
import { EDevice } from '../../../redux/sequence/enums';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { Type } from '../../../types/instant-lease-api';
import { formatGermanPrice } from '../../../utils/getter';
import { CapitalizeFirstLetter } from '../../../utils/helper';
import Button from '../../b2b/components/button/button';
import UnknownCar from '../../b2b/components/images/unknown-car.png';
import PartnerLogo from '../../b2b/components/partner-logo/partner-logo';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import {
  getFuelType,
  getGearBoxType,
  getVehicleBody,
} from '../car-details/car-data-mapper';
import PricingDetails from '../pricing-details/pricing-details';
import './bootstrap.css';

export interface FlexCarDetails {
  duration: string;
  gross: number;
  mileage: number;
  net: number;
  title: string;
  vehicleImageUrl: string;
  type: Type.B2C | Type.B2B;
}

const Bootstrap: React.FC = () => {
  // Lease Application Storage
  const { carData } = useSelector((state: RootState) => state.leaseApp);

  // History hook
  const navigate = useNavigate();

  const { device } = useSelector((state: RootState) => state.sequence);

  // Handle on button click
  const handleOnClick = (path: string) => navigate(path);

  // Format price with german price format
  const { format } = formatGermanPrice();

  // Current URL
  const searchParams = new URLSearchParams(document.location.search);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // URL Variables
  const offerId = searchParams.get('offerId');
  const publicationId = searchParams.get('publicationId');
  const pricingId = searchParams.get('pricingId');

  // if no offer or carData move to no offer page
  useEffect(() => {
    if (!publicationId && !pricingId && !offerId) {
      navigate('/no-offer');
    }
  }, [navigate, offerId, pricingId, publicationId]);

  // Get current car regardless of saved data
  useEffect(() => {
    if ((publicationId && pricingId) || offerId) {
      dispatch(fetchCarDetails({ publicationId, pricingId, offerId }));
    }
  }, [dispatch, offerId, pricingId, publicationId]);

  const carInformation = [
    {
      label: 'Fahrzeugart',
      value:
        carData?.carVehicleState === 'USED' ? 'Gebrauchtwagen' : 'Neuwagen',
    },
    {
      label: 'Beschleunigung',
      value: carData?.carAccelerationToHundredKph
        ? `${format(Number(carData.carAccelerationToHundredKph)).replace(
            '€',
            '',
          )}s (0-100km/h)`
        : '-',
    },
    {
      label: 'Karosserie',
      value: carData?.carBodyType && getVehicleBody(carData?.carBodyType),
    },
    { label: 'Farbe', value: carData?.carColorName ?? '-' },
    {
      label: 'Kraftstoff',
      value: CapitalizeFirstLetter(
        ((carData?.carFuelType && getFuelType(carData?.carFuelType)) || '')
          .toLowerCase()
          .replace('_', ' ') ?? '-',
      ),
    },
    { label: 'Türen', value: carData?.carNumberOfDoors ?? '-' },
    {
      label: 'Getriebe',
      value: CapitalizeFirstLetter(
        (
          (carData?.carTransmissionType &&
            getGearBoxType(carData?.carTransmissionType)) ||
          ''
        )
          .toLowerCase()
          .replace('_', ' ') ?? '-',
      ),
    },
    {
      label: 'Effizienzklasse',
      value: carData?.efficiencyClass ?? '-',
    },
    {
      label: 'Batterie Kapazität',
      value: carData?.batteryCapacity ? `${carData.batteryCapacity} KWh` : '-',
    },
    {
      label: 'Reichweite',
      value: carData?.electricalRange ? `${carData.electricalRange} km` : '-',
    },
    {
      label: 'Leistung PS',
      value: `${carData?.carHorsePower} PS`,
    },
    {
      label: 'Leistung kW',
      value: `${carData?.carPowerKiloWatt} kW`,
    },
    {
      label: 'max. Geschwindigkeit',
      value: carData?.carMaxSpeed ? `${carData.carMaxSpeed} km/h` : '-',
    },
  ];

  return (
    <>
      <div className='left container'>
        <PartnerLogo />
      </div>

      <div className='bootstrap grid-template container'>
        <div className='template-body-wide'>
          {carData && (
            <div className='subheader'>
              <PricingDetails visibility />
              <div className='car-details'>
                <div className='offer-details'>
                  <p className='content-normal offer'>
                    {carData?.carVehicleState === 'USED'
                      ? `Gebrauchtwagen ${
                          carData?.carUsedMileage
                            ? `| ${carData?.carUsedMileage} km`
                            : ''
                        }`
                      : 'Neu'}{' '}
                    |{' '}
                    {CapitalizeFirstLetter(
                      (
                        (carData?.carFuelType &&
                          getFuelType(carData?.carFuelType)) ||
                        ''
                      )
                        .toLowerCase()
                        .replace('_', ' '),
                    )}
                  </p>{' '}
                  {/* <p className='small-text dark-grey consumption space'>
                    {carData?.carCo2} g/km (komb.)*,{' '}
                    {carData?.carFuelEconomyCombined ||
                      carData?.carPowerConsumptionCombined ||
                      0}
                    /100 km (komb.)* ({carData?.carConsumptionAndEmissionTest})
                  </p> */}
                  <div className='lease-info'>
                    <p className='content-bold'>Vertragsdaten</p>{' '}
                    <div className='lease-details'>
                      {carData?.totalListPrice !== undefined &&
                        carData?.totalListPrice !== 0 && (
                          <>
                            <span className='lease-key dark-grey content-normal'>
                              Listenpreis
                            </span>
                            <span className='lease-value content-normal'>
                              {format(carData?.totalListPrice)}
                            </span>
                          </>
                        )}
                      {carData?.duration && (
                        <>
                          <span className='lease-key dark-grey content-normal'>
                            Laufzeit
                          </span>
                          <span className='lease-value content-normal'>
                            {' '}
                            {carData?.duration} Monate{' '}
                          </span>
                        </>
                      )}
                      {carData?.mileageYear && (
                        <>
                          {' '}
                          <span className='lease-key dark-grey content-normal'>
                            Laufleistung
                          </span>
                          <span className='lease-value content-normal'>
                            {' '}
                            {Math.floor(
                              carData?.mileageYear || 0,
                            ).toLocaleString('de-DE')}{' '}
                            km/Jahr
                          </span>
                        </>
                      )}
                      {carData?.exceedingMileagePrice !== undefined &&
                        carData?.exceedingMileagePrice !== 0 && (
                          <>
                            <span className='lease-key dark-grey content-normal'>
                              Mehrkilometer-Kosten
                            </span>
                            <span className='lease-value content-normal'>
                              {' '}
                              {format(carData?.exceedingMileagePrice).replace(
                                '€',
                                '',
                              )}
                              ct/km
                            </span>
                          </>
                        )}
                      {carData?.inferiorMileagePrice !== undefined &&
                        carData?.inferiorMileagePrice !== 0 && (
                          <>
                            <span className='lease-key dark-grey content-normal'>
                              Minderkilometer-Erstattung
                            </span>
                            <span className='lease-value content-normal'>
                              {' '}
                              {format(carData?.inferiorMileagePrice).replace(
                                '€',
                                '',
                              )}
                              ct/km
                            </span>
                          </>
                        )}
                    </div>
                  </div>
                  <div className='lease-info'>
                    <p className='content-bold'>Einmalkosten</p>
                    <div className='lease-details'>
                      {carData?.downPayment !== undefined && (
                        <>
                          <span className='lease-key dark-grey content-normal'>
                            Sonderzahlung
                          </span>
                          <span className='lease-value content-normal'>
                            {' '}
                            {format(carData?.downPayment)}
                          </span>
                        </>
                      )}

                      <>
                        <span className='lease-key dark-grey content-normal'>
                          Logistik
                        </span>
                        <span className='lease-value content-normal'>
                          {' '}
                          {carData?.additionalCost?.logistics
                            ? format(carData?.additionalCost?.logistics)
                            : '-'}
                        </span>
                      </>

                      <>
                        <span className='lease-key dark-grey content-normal'>
                          Rückholkosten
                        </span>
                        <span className='lease-value content-normal'>
                          {' '}
                          {carData?.additionalCost?.returnFreight
                            ? format(carData?.additionalCost?.returnFreight)
                            : '-'}
                        </span>
                      </>

                      <>
                        <span className='lease-key dark-grey content-normal'>
                          Zulassungskosten
                        </span>
                        <span className='lease-value content-normal'>
                          {carData?.additionalCost?.registration
                            ? format(carData?.additionalCost?.registration)
                            : '-'}
                        </span>
                      </>

                      <>
                        <span className='lease-key dark-grey content-normal'>
                          Überführungskosten
                        </span>
                        <span className='lease-value content-normal'>
                          {carData?.additionalCost?.freight
                            ? format(carData?.additionalCost?.freight)
                            : '-'}
                        </span>
                      </>
                    </div>
                  </div>
                  {carData?.services && (
                    <div className='lease-info last'>
                      <p className='content-bold'>Monatliche Kosten</p>{' '}
                      <div className='lease-details'>
                        <span className='lease-key dark-grey content-normal'>
                          Finanz-Leasingrate
                        </span>
                        <span className='lease-value content-normal'>
                          {carData?.services?.finance === null ? (
                            <>-</>
                          ) : (
                            <>{format(carData?.services?.finance)}</>
                          )}
                        </span>

                        <span className='lease-key dark-grey content-normal'>
                          Technik-Service
                        </span>
                        <span className='lease-value content-normal'>
                          {carData?.services?.technic === null ? (
                            <>-</>
                          ) : (
                            <>{format(carData?.services?.technic)}</>
                          )}
                        </span>

                        <span className='lease-key dark-grey content-normal'>
                          Versicherungs-Service
                        </span>
                        <span className='lease-value content-normal'>
                          {carData?.services?.insurance === null ? (
                            <>-</>
                          ) : (
                            <>{format(carData?.services?.insurance)}</>
                          )}
                        </span>

                        <span className='lease-key dark-grey content-normal'>
                          Fuel & Charge Karte
                        </span>
                        <span className='lease-value content-normal'>
                          {carData?.services?.fuelCard === null ? (
                            <>-</>
                          ) : (
                            <>{format(carData?.services?.fuelCard)}</>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='car-visual'>
                  <div
                    className='car-details-image'
                    style={{
                      backgroundImage: `url(${
                        _.isEmpty(carData?.defaultImage)
                          ? UnknownCar
                          : carData?.defaultImage
                      })`,
                    }}
                  />
                  <div className='car-information left'>
                    {carInformation
                      .filter((info) => info.value)
                      .map(({ label, value }, index) => (
                        <div key={index}>
                          <p className='content-normal'>{label}</p>
                          <p className='content-normal'>{value}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {device === EDevice.MOBILE ? (
                <ButtonGroup
                  type='back-next'
                  className='back-next medium'
                  buttonOneProps={{
                    type: 'button',
                    theme: 'primary',
                    children: 'Weiter',
                  }}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    dispatch(setIsAnimated(true));
                    handleOnClick('/login');
                  }}
                ></ButtonGroup>
              ) : (
                <Button
                  type='button'
                  className='button-text long'
                  theme='primary'
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    dispatch(setIsAnimated(true));
                    handleOnClick('/login');
                  }}
                >
                  Weiter
                </Button>
              )}
            </div>
          )}

          {!carData && (
            <img src={UnknownCar} alt='car' className='car-list unknown-car' />
          )}
        </div>
      </div>
    </>
  );
};

export default Bootstrap;
