import _, { toInteger } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cancelLeaseApplication } from '../../../api/instant-lease-api';
import {
  fetchCarDetails,
  fetchLeaseApp,
} from '../../../redux/leaseApp/lease-app-slice';
import { EDevice } from '../../../redux/sequence/enums';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import { CarData, Partner, Type } from '../../../types/instant-lease-api';
import {
  formatGermanPrice,
  getIsAllowedToBeCanceled,
} from '../../../utils/getter';
import { CapitalizeFirstLetter } from '../../../utils/helper';
import { ReactComponent as ArrowRight } from '../../b2b/components/icons/chevron-right.svg';
import { ReactComponent as ContractCancel20 } from '../../b2b/components/icons/contract_delete_20.svg';
import { ReactComponent as ContractCancel24 } from '../../b2b/components/icons/contract_delete_24.svg';
import UnknownCar from '../../b2b/components/images/unknown-car.png';
import ConfirmationModal from '../../b2c/components/confirmation-modal/confirmation-modal';
import {
  getFuelType,
  getGearBoxType,
  getVehicleBody,
  getVehicleType,
} from './car-data-mapper';

const CarDetails: React.FC<{
  showCancelButton?: boolean;
  carInfo?: CarData;
}> = ({ showCancelButton = true, carInfo }) => {
  // Lease Applications Store
  const { carData, activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Lease Applications Store
  const { emailVerified } = useSelector((state: RootState) => state.auth);

  // Lease Applications Store
  const { device } = useSelector((state: RootState) => state.sequence);

  // History hook
  const navigate = useNavigate();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Lease Application Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Confirmation modal control
  const [confirmationVisibility, setConfirmationVisibility] =
    useState<boolean>(false);

  // Cancellation Loading
  const [loading, setLoading] = useState<boolean>(false);

  //Car Details
  const [carDetails, setCarDetails] = useState<CarData | null | undefined>(
    carInfo || carData,
  );

  // Variables
  const isCommercial = activeApplication?.type === Type.B2C;

  const vehicleData = activeApplication?.offer?.vehicle_data;

  const leasingOptions = activeApplication?.offer?.leasing_options;

  const extraMileage = isCommercial
    ? leasingOptions?.finance?.inferior_mileage?.gross
    : leasingOptions?.finance?.inferior_mileage?.net;

  const exceededMileage = isCommercial
    ? leasingOptions?.finance?.exceeding_mileage?.gross
    : leasingOptions?.finance?.exceeding_mileage?.net;

  // Vehicle State
  const getVehicleState = () => {
    if (carDetails?.carVehicleState) {
      return carDetails?.carVehicleState;
    }
    return activeApplication?.vin === '' ? 'NEU' : 'USED';
  };

  // Downpayment
  const getDownPayment = () => {
    if (carDetails?.downPayment !== undefined) {
      return carDetails?.downPayment;
    }
    return isCommercial
      ? leasingOptions?.downPayment?.gross
      : leasingOptions?.downPayment?.net;
  };

  // Format price with german price format
  const { format } = formatGermanPrice();

  // Values
  const {
    image,
    state,
    fuel,
    usedMileage,
    downPayment,
    totalListPrice,
    exceedingMileagePrice,
    inferiorMileagePrice,
    duration,
    mileage,
    additionalCost,
  } = {
    image: carDetails?.defaultImage || vehicleData?.vehicleImageUrl,
    state: getVehicleState(),
    fuel: carDetails?.carFuelType,
    usedMileage: carDetails?.carUsedMileage,
    downPayment: getDownPayment(),
    totalListPrice:
      carDetails?.totalListPrice ||
      toInteger(vehicleData?.emission_data?.totalPrice),
    exceedingMileagePrice: carDetails?.exceedingMileagePrice || exceededMileage,
    inferiorMileagePrice: carDetails?.inferiorMileagePrice || extraMileage,
    duration: carDetails?.duration || leasingOptions?.duration,
    mileage: carDetails?.mileageYear || leasingOptions?.mileage,
    additionalCost: {
      freight:
        carDetails?.additionalCost?.freight ||
        vehicleData?.additional_cost?.freight,
      logistics:
        carDetails?.additionalCost?.logistics ||
        vehicleData?.additional_cost?.logistics,
      registration:
        carDetails?.additionalCost?.registration ||
        vehicleData?.additional_cost?.registration,
      returnFreight:
        carDetails?.additionalCost?.returnFreight ||
        vehicleData?.additional_cost?.returnFreight,
    },
  };

  const carInformation = [
    {
      label: 'Fahrzeugart',
      value: state ? getVehicleType(state) : '-',
    },
    {
      label: 'Beschleunigung',
      value: carData?.carAccelerationToHundredKph
        ? `${format(Number(carData.carAccelerationToHundredKph)).replace(
            '€',
            '',
          )}s (0-100km/h)`
        : '-',
    },
    {
      label: 'Karosserie',
      value: carData?.carBodyType && getVehicleBody(carData?.carBodyType),
    },
    { label: 'Farbe', value: carDetails?.carColorName ?? '-' },
    {
      label: 'Kraftstoff',
      value: CapitalizeFirstLetter(
        (carData?.carFuelType && getFuelType(carData.carFuelType))
          ?.toLowerCase()
          .replace('_', ' ') ?? '-',
      ),
    },
    { label: 'Türen', value: carDetails?.carNumberOfDoors ?? '-' },
    {
      label: 'Getriebe',
      value: CapitalizeFirstLetter(
        (
          carData?.carTransmissionType &&
          getGearBoxType(carData.carTransmissionType)
        )
          ?.toLowerCase()
          .replace('_', ' ') ?? '-',
      ),
    },
    {
      label: 'Effizienzklasse',
      value: carDetails?.efficiencyClass ?? '-',
    },
    {
      label: 'Batterie Kapazität',
      value: carDetails?.batteryCapacity
        ? `${carDetails.batteryCapacity} KWh`
        : '-',
    },
    {
      label: 'Reichweite',
      value: carDetails?.electricalRange
        ? `${carDetails.electricalRange} km`
        : '-',
    },
    {
      label: 'Leistung',
      value:
        carDetails?.carPowerKiloWatt && carDetails?.carHorsePower
          ? `${carDetails.carPowerKiloWatt} kW | ${carDetails.carHorsePower} PS`
          : '-',
    },
    {
      label: 'max. Geschwindigkeit',
      value: carDetails?.carMaxSpeed ? `${carDetails.carMaxSpeed} km/h` : '-',
    },
  ];

  // handle flex leasing application
  useEffect(() => {
    const offerId = activeApplication?.offer?.armada_id;
    const isFlex = activeApplication?.partner === Partner.ALD_FLEX;
    if (isFlex && offerId) {
      dispatch(fetchCarDetails({ offerId }));
    }
  }, [
    activeApplication?.offer?.armada_id,
    activeApplication?.partner,
    dispatch,
    navigate,
  ]);

  // handle change and update of the car data
  useEffect(() => {
    setCarDetails(carData);
  }, [carData]);

  // Handle cancel lease
  const cancelLeaseApp = () => {
    setLoading(true);
    if (getIsAllowedToBeCanceled(activeApplication?.state)) {
      cancelLeaseApplication(accessToken, activeApplication?.uuid as string)
        .then(() => {
          dispatch(
            fetchLeaseApp({
              accessToken,
              leaseAppId: activeApplication?.uuid as string,
            }),
          ).then(() => {
            setLoading(false);
            setConfirmationVisibility(false);
            navigate('/');
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className='subheader-body-content container'>
      <div>
        <div>
          {state && (
            <span className='content-normal offer'>
              {state === 'USED'
                ? `Gebrauchtwagen ${usedMileage ? `| ${usedMileage} km` : ''}`
                : 'Neu'}{' '}
              {fuel ? `| ${getFuelType(fuel)}` : ''}
            </span>
          )}
          <br />
          {/* {emissions && (
                <p
                  className='small-text dark-grey consumption'
                  style={{ paddingTop: '24px' }}
                >
                  {co2} g/km (komb.)*, {co2km}/100 km (komb.)* ({emissions})
                </p>
              )} */}
        </div>
        <div className='lease-info'>
          <h3 className='content-bold'>Vertragsdaten</h3>
          <div className='lease-details'>
            {totalListPrice !== undefined && totalListPrice !== 0 && (
              <>
                <span className='lease-key dark-grey content-normal'>
                  Listenpreis
                </span>
                <span className='lease-value content-normal'>
                  {format(totalListPrice || 0)}
                </span>
              </>
            )}
            {duration && (
              <>
                <span className='lease-key dark-grey content-normal'>
                  Laufzeit
                </span>
                <span className='lease-value content-normal'>
                  {' '}
                  {duration} Monate{' '}
                </span>
              </>
            )}
            {mileage && (
              <>
                {' '}
                <span className='lease-key dark-grey content-normal'>
                  Laufleistung
                </span>
                <span className='lease-value content-normal'>
                  {' '}
                  {Math.floor(mileage || 0).toLocaleString('de-DE')} km/Jahr
                </span>
              </>
            )}
            {exceedingMileagePrice !== undefined &&
              exceedingMileagePrice !== 0 && (
                <>
                  <span className='lease-key dark-grey content-normal'>
                    Mehrkilometer-Kosten
                  </span>
                  <span className='lease-value content-normal'>
                    {' '}
                    {format(exceedingMileagePrice).replace('€', '')}
                    ct/km
                  </span>{' '}
                </>
              )}
            {inferiorMileagePrice !== undefined &&
              inferiorMileagePrice !== 0 && (
                <>
                  <span className='lease-key dark-grey content-normal'>
                    Minderkilometer-Erstattung
                  </span>
                  <span className='lease-value content-normal'>
                    {' '}
                    {format(inferiorMileagePrice).replace('€', '')}
                    ct/km
                  </span>
                </>
              )}
          </div>
        </div>
        <div
          className={`lease-info ${carData?.product === 'FLEX' ? 'last' : ''}`}
        >
          <>
            <h3 className='content-bold'>
              <span>Einmalkosten</span>
            </h3>
            <div className='lease-more-details'>
              {downPayment !== undefined && (
                <>
                  <span className='lease-key dark-grey content-normal'>
                    Sonderzahlung
                  </span>
                  <span className='lease-value content-normal'>
                    {downPayment ? format(downPayment) : '-'}
                  </span>
                </>
              )}

              <>
                <span className='lease-key dark-grey content-normal'>
                  Logistik
                </span>
                <span className='lease-value content-normal'>
                  {' '}
                  {carData?.additionalCost?.logistics
                    ? format(carData?.additionalCost?.logistics)
                    : '-'}
                </span>
              </>

              <>
                <span className='lease-key dark-grey content-normal'>
                  Rückholkosten
                </span>
                <span className='lease-value content-normal'>
                  {' '}
                  {additionalCost?.returnFreight
                    ? format(additionalCost?.returnFreight)
                    : '-'}
                </span>
              </>

              <>
                <span className='lease-key dark-grey content-normal'>
                  Zulassungskosten
                </span>
                <span className='lease-value content-normal'>
                  {' '}
                  {additionalCost?.registration
                    ? format(additionalCost?.registration)
                    : '-'}
                </span>
              </>

              <>
                <span className='lease-key dark-grey content-normal'>
                  Überführungskosten
                </span>
                <span className='lease-value content-normal'>
                  {' '}
                  {additionalCost?.freight
                    ? format(additionalCost?.freight)
                    : '-'}
                </span>
              </>
            </div>
          </>
        </div>
        {carData?.product !== 'FLEX' && (
          <div className='lease-info last'>
            <p className='content-bold'>Monatliche Kosten</p>{' '}
            <div className='lease-details'>
              <span className='lease-key dark-grey content-normal'>
                Finanz-Leasingrate
              </span>
              <span className='lease-value content-normal'>
                {carData?.services?.finance
                  ? format(carData?.services?.finance)
                  : '-'}
              </span>
              <span className='lease-key dark-grey content-normal'>
                Technik-Service
              </span>
              <span className='lease-value content-normal'>
                {carData?.services?.technic
                  ? format(carData?.services?.technic)
                  : '-'}
              </span>

              <span className='lease-key dark-grey content-normal'>
                Versicherungs-Service
              </span>
              <span className='lease-value content-normal'>
                {carData?.services?.insurance
                  ? format(carData?.services?.insurance)
                  : '-'}
              </span>

              <span className='lease-key dark-grey content-normal'>
                Fuel & Charge Karte
              </span>
              <span className='lease-value content-normal'>
                {carData?.services?.fuelCard === null ? (
                  <>-</>
                ) : (
                  <>{format(carData?.services?.fuelCard || 0)}</>
                )}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='car-visual'>
        <div
          className='car-details-image'
          style={{
            backgroundImage: `url(${_.isEmpty(image) ? UnknownCar : image})`,
          }}
        />
        <div className='car-information'>
          {carInformation
            .filter((info) => info.value)
            .map(({ label, value }, index) => (
              <div key={index}>
                <p className='content-normal'>{label}</p>
                <p className='content-normal'>{value}</p>
              </div>
            ))}
        </div>
        {showCancelButton && emailVerified && (
          <p
            role='presentation'
            className='cancel-application-text content-bold error link'
            onClick={() => setConfirmationVisibility(true)}
          >
            {device === EDevice.MOBILE ? (
              <ContractCancel20 />
            ) : (
              <ContractCancel24 />
            )}
            {carData?.product !== 'FLEX' ? (
              `Leasingantrag abbrechen`
            ) : (
              <span>Auto-Abo abbrechen</span>
            )}
          </p>
        )}
      </div>
      <ConfirmationModal
        isOpen={confirmationVisibility}
        onClose={() => setConfirmationVisibility(false)}
        confirmationText='Leasingantrag stornieren?'
        cancel={() => setConfirmationVisibility(false)}
        onLoading={loading}
        success={cancelLeaseApp}
      >
        <ul className='arrow-list'>
          <li className='arrow-list-item'>
            <ArrowRight className='arrow-list-icon' />
            <p className='content-normal dark-grey'>
              Wenn du deinen Leasingantrag stornierst, wird das Fahrzeug nicht
              weiter für dich reserviert und für dich fallen keine weiteren
              Schritte an. Falls du Interesse an einem anderen Fahrzeug hast,
              schaue dich gern erneut bei uns um.
            </p>
          </li>
          <li className='arrow-list-item'>
            <ArrowRight className='arrow-list-icon' />
            <p className='content-normal dark-grey'>
              Über den Button "Fortsetzen" gelangst du zurück zu deinem
              Leasingantrag.
            </p>
          </li>
        </ul>
      </ConfirmationModal>
    </div>
  );
};

export default CarDetails;
