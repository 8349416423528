import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { Document } from '../../../../redux/leaseApp/types';
import { RootState } from '../../../../redux/types';
import { getBulletPoints, getText } from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import { validateDocuments } from '../../../../utils/validatetor';
import FormLayout from '../../../common/form-layout/form-layout';
import ArrowList from '../../components/arrow-list/arrow-list';
import UploadBox from '../../components/upload-box/upload-box';
import ButtonGroup from '../../molecules/button-group/button-group';
import './required-documents.css';

const RequiredDocuments: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const cache = useSelector((state: RootState) => state.cache);

  // Navigation class
  const { nextRoute } = new Navigation();

  // History Hook used Nav to the next page
  const navigate = useNavigate();

  // Get documents
  const getRequiredDocuments =
    activeApplication && validateDocuments(activeApplication);

  const documentCollection = () => {
    const documents: Document[] = getRequiredDocuments
      ? [...getRequiredDocuments.requiredDocuments]
      : [];

    return documents;
  };

  return (
    <Layout
      heading={getText('upload_headline')}
      stepStatus
      subHeading='Beachte dabei bitte, dass du die verschiedenen Dokumente an der richtigen Stelle hochlädst, da wir den Vorgang sonst nicht weiterführen können.'
    >
      {documentCollection().length > 0 && (
        <FormLayout sectionTitle='Unternehmensnachweis: '>
          {documentCollection().map((doc, index) => (
            <div key={index}>
              {doc.enum === 'PROOF_OF_COMMERCIAL_ACTIVITY' &&
              getBulletPoints(activeApplication) ? (
                <>
                  <p className='content-normal dark-grey'>
                    {getText(
                      'upload_single_companies_commercial_activity_description_headline',
                    )}
                  </p>
                  <ArrowList
                    className='content-normal dark-grey'
                    list={[
                      `Deine ${doc.name}`,
                      getText(
                        'upload_single_companies_commercial_activity_description_bulletpoint2',
                      ),
                      getText(
                        'upload_single_companies_commercial_activity_description_bulletpoint3',
                      ),
                    ]}
                  />
                  <UploadBox fileName={doc.name} documentType={doc.enum} />
                </>
              ) : (
                <UploadBox fileName={doc.name} documentType={doc.enum} />
              )}
            </div>
          ))}
        </FormLayout>
      )}

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        buttonOneProps={{
          type: 'submit',
          disabled: activeApplication
            ? !validateDocuments(activeApplication, cache).exist
            : false,
          dataTestId: 'proceed',
          children: 'weiter',
          onClick: () => navigate(nextRoute()),
        }}
      />
    </Layout>
  );
};

export default RequiredDocuments;
